@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital@1&display=swap");

// Customizations
$body-color: #14141f;
$body-bg: #ebe7e7;
$primary: #006cb5;
$enable-rounded: false;
$enable-button-pointers: false;
$font-family-base: "Merriweather Sans", sans-serif;

// Bootstrap's CSS
// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom CSS styles

html {
  scroll-behavior: smooth;
}

nav .nav-link.active {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 0.5rem;
}

h1.quote {
  font-family: "EB Garamond", serif;
}

p.lh-md {
  line-height: 1.75;
}

a.text-whatsapp {
  color: #128c7e;
}

.row.minh-100 {
  min-height: 100vh;
}

.minh-65 {
  min-height: 65vh;
}

nav.nav-scrolled-down {
  background-color: white;
}

.navbar {
  transition: all 0.5s;
}

img.home-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.4;
}

section {
  scroll-margin: 72px;
}

@media screen and (max-width: 357px) {
  #navbar-container {
    justify-content: center;
  }

  section {
    scroll-margin: 112px;
  }
}
